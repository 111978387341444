<template>
  <div class="form">
    <img src="@/assets/svg/logo/red.svg" alt="" class="form__logo">
    <p class="form__title">{{ $t('Login') }}</p>
    <div class="form__action">
      <ui-input
          v-model="form.login"
          :label="$t('nickname-or-email')"
          :error="$v.form.login.$error || catchError"
          :error-text='getLoginErrorText'
          @input="catchError = false"
      ></ui-input>
      <ui-input
          :label="$t('password')"
          v-model="form.password"
          :error="$v.form.password.$error || catchError"
          :error-text='getPasswordErrorText'
          show-password
      ></ui-input>
      <span @click="recovery" class="form__action-recovery">{{ $t('forgot-password') }}</span>
      <span class="form__action-line"></span>
      <ui-button :disabled="disabled" @click="submit">{{ $t('log-in') }}</ui-button>
      <router-link to="/auth" class="form__action-auth">
        <ui-button color="outline">{{ $t('registration') }}</ui-button>
        <p>{{ $t('for-roles') }}</p>
      </router-link>
      <div class="form__action-helper">
        <p>{{ $t('registration-for-active') }}</p>
        <div class="form__action-helper-actions">
          <a href="https://apps.apple.com/ru/app/ecophone/id6446772826?l=en" target="_blank">
            <img src="@/assets/images/qr-apple.gif" alt="" />
            <p>App Store</p>
          </a>
          <a href="https://play.google.com/store/apps/details?id=apro.eco.phone" target="_blank">
            <img src="@/assets/images/qr-android.gif" alt="" />
            <p>Google Play</p>
          </a>
        </div>
      </div>
    </div>
    <UiAlert v-model="showAlert">
      <div class="ui-alert__title">{{ $t('please-register') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="$router.push('/auth')">{{ $t('registration') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import UiAlert from "@/components/ui/UiAlert.vue";
export default {
  name: "LoginForm",
  components: {
    UiAlert,
    UiButton: () => import("@/components/ui/UiButton"),
    UiInput: () => import("@/components/ui/UiInput"),
  },
  validations() {
    return {
      form: {
        login: {
          required
        },
        password: {
          required
        }
      }
    }
  },
  data() {
    return {
      form: {
        login: '',
        password: ''
      },
      catchError: false,
      catchErrorText: '',
      showAlert: false,
    }
  },
  methods: {
    ...mapActions(['login', 'reqLogout']),
    submit() {
      this.$v.form.$touch()
      this.catchError = false

      if (!this.$v.form.$anyError) {
        localStorage.clear()
        this.login({ loginOrEmail: this.form.login, password: this.form.password }).then((res) => {
          if (res.data?.user && !res.data.user.mustChangePassword && res.data?.user.role) {
            this.$router.push('/')
          }else if (res.data.result === 'UserDeleted') {
            this.showAlert = true
          }else if (res.data?.user.role === null) {
            this.$router.push('/profile/edit-profile')
          }else {
            this.$emit('savePassword', this.form.password)
          }
        }).catch((res) => {
          this.catchErrorText = res.response.data.message
          this.catchError = true
        })
      }
    },

    enter(e) {
      this.$v.form.$touch()
      if(e.keyCode === 13) {
        this.submit()
      }
    },

    recovery () {
      let mailFormat =  /\S+@\S+\.\S+/;
      if(this.form.login.match(mailFormat)) {
        this.$emit('recovery', this.form.login)
      }else {
        this.$emit('recovery')
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.enter)
  },

  destroyed() {
    document.removeEventListener('keydown',  this.enter, false);
  },

  computed: {
    getLoginErrorText() {
      return this.catchErrorText === 'USER_IS_BLOCKED' ? this.$t('blocked-error') : this.catchErrorText === 'USER_DELETED' ? 'Такой адрес не найден' : this.$t('error-nickname-or-password')
    },
    getPasswordErrorText() {
      return this.catchErrorText === 'USER_IS_BLOCKED' ? this.$t('blocked-error') : this.catchErrorText === 'USER_DELETED' ? 'Такой адрес не найден' : this.$t('error-password')
    },
    disabled() {
      return !this.form.login || !this.form.password
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    width: 44px;
    height: 50px;
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 30px;
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &-recovery {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #343432;
      align-self: flex-end;
    }

    &-line {
      border-bottom: 1px solid #F5F5F5;
      margin-bottom: 10px;
    }

    &-auth {
      p {
        text-align: center;
        color: #000000;
        font-family: 'Gotham Pro Regular';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &-helper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        text-align: center;
        color: #000000;
        font-family: 'Gotham Pro Medium';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &-actions {
        display: flex;
        justify-content: center;
        gap: 20px;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
</style>
