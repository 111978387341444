<template>
  <div class="recover-account-second-step recover">
    <ui-input
        :label="$t('enter-your-email-to-recover-your-account')"
        v-model="email"
        :error="$v.email.$error"
        :error-text="$t('error-email')"
        @input="catchError = false"
    />
    <div class="recover__line"></div>
    <ui-button :disabled="!email || $v.email.$anyError" @click="submit">
      {{ $t('continue') }}
    </ui-button>
    <ui-button @click="$emit('back')" color="outline">
      {{ $t('cancel-1') }}
    </ui-button>
  </div>
</template>


<script>
import { email, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "RecoverAccountSecondStep",
  inject: ['form'],
  components: {
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
  },
  validations() {
    return {
      email: {
        required,
        email
      },
    }
  },
  data() {
    return {
      email: '',
      catchError: false
    }
  },
  mounted() {
    document.addEventListener('keydown', this.enter)
  },

  methods: {
    ...mapActions(['restoreAccount']),
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.restoreAccount({
          email: this.email
        }).then(() => {
          this.form.email = this.email
          this.$emit('continue')
        }).catch(() => {
          this.catchError = true
        })
      }
    },
    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recover {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 15px;

  &__line {
    border-bottom: 1px solid #F5F5F5;
  }
}
</style>
