<template>
  <div class="save-password">
    <img src="@/assets/svg/logo/red.svg" alt="">
    <p class="save-password__title">{{ $t('you-need-to-change-password') }}</p>
    <form class="save-password__form">
      <ui-input
          :label="$t('enter-new-password')"
          v-model="password"
          show-password
          :error="$v.password.$anyError"
          :error-text="getPasswordErrorText"
      />
      <ui-input
          :label="$t('confirm-new-password')"
          v-model="repeatPassword"
          show-password
          :error="$v.repeatPassword.$anyError"
          :error-text="getRepeatPasswordErrorText"
      />
    </form>
    <div class="save-password__line"></div>
    <div class="save-password__actions">
      <ui-button :disabled="disabled || $v.$anyError" @click="submit">
        {{ $t('log-in') }}
      </ui-button>
      <ui-alert v-model="showAlert">
        <h5 class="ui-alert__title">
          {{ $t('password-changed-successfully') }}
        </h5>
        <UiButton @click="nextStep">
          {{ $t('continue') }}
        </UiButton>
      </ui-alert>
    </div>
  </div>
</template>


<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import {mapActions, mapMutations} from "vuex";
import {setCookie} from "@/utils/cookie";
export default {
  name: "SavePassword",
  components: {
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
    UiAlert: () => import("@/components/ui/UiAlert"),
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        required,
        sameAs: sameAs('password'),
        minLength: minLength(8)
      }
    }
  },

  props: {
    oldPassword: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      password: '',
      repeatPassword: '',
      showAlert: false,
      token: '',
    }
  },

  methods: {
    ...mapMutations(['setAuth']),
    ...mapActions(['changePassword']),
    nextStep () {
      this.setAuth(true)
      setCookie('authToken', this.token)
      this.$router.push('/')
    },
    submit () {
      this.$v.$touch();
      this.showAlert = false;
      if (!this.$v.$anyError) {
        this.changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.password
        }).then((res) => {
          this.showAlert = true;
          this.token = res.token
          // this.setAuth(true)
        })
      }
    }
  },

  computed: {
    getPasswordErrorText() {
      if (!this.$v.password.minLength || !this.$v.password.required) {
        return this.$t('error-short-password')
      }

      return ''
    },
    getRepeatPasswordErrorText() {
      if (!this.$v.repeatPassword.minLength || !this.$v.repeatPassword.required) {
        return this.$t('error-short-password')
      } else if (!this.$v.repeatPassword.sameAs) {
        return this.$t('error-password-do-not-match')
      }

      return  ''
    },
    disabled() {
      return !this.password || !this.repeatPassword
    }
  },

  mounted() {
    document.addEventListener('keydown', this.enter)
    console.log(this.oldPassword);
  }
}
</script>


<style lang="scss" scoped>
.save-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  img {
    width: 44px;
    height: 50px;
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 30px;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  &__line {
    border-bottom: 1px solid #FFFFFF;
    width: 100%;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}
</style>
