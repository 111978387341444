<template>
  <div class="recovery">
    <img src="@/assets/svg/logo/red.svg" alt="">
    <h1 class="recovery__title">{{ $t('password-recovery') }}</h1>
    <password-recovery-first-step
        v-if="step === 1"
        @back="$emit('back')"
        @continue="step = 2"
        :userEmail="email"
    />
    <password-recovery-second-step
        v-if="step === 2"
        @continue="step = 3"
    />
    <password-recovery-third-step
        v-if="step === 3"
        @back="$emit('back')"
    />
  </div>

</template>

<script>
export default {
  name: "PasswordRecovery",
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  provide: {
    form: {
      email: "",
      password: "",
      authCode: ""
    }
  },
  components: {
    PasswordRecoveryFirstStep: () => import("@/components/template/login/PasswordRecoveryFirstStep"),
    PasswordRecoverySecondStep: () => import("@/components/template/login/PasswordRecoverySecondStep"),
    PasswordRecoveryThirdStep: () => import("@/components/template/login/PasswordRecoveryThirdStep"),
  },
  data() {
    return {
      step: 1,
    }
  }
}
</script>

<style lang="scss" scoped>
.recovery {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 44px;
    height: 50px;
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #000000;
    margin-bottom: 15px;
  }
}
::v-deep .login__form {
  padding: 30px 34px 35px 34px !important;
}
</style>
