<template>
  <div class="recover-account-third-step recover">
    <p class="recover__title">{{ $t('code-to-email', {email: form.email})}}</p>
    <ui-input
        :label="$t('enter-confirmation-code')"
        v-model="authCode"
        :error="$v.authCode.$error || catchError"
        :error-text='getCodeErrorText'
    />
    <div class="recover__line"></div>
    <div class="recover__actions">
      <ui-button @click="sendCodeAgain" :disabled="buttonDisabled" color="outline">
        {{ $t('send-code-again') }}
      </ui-button>
      <UiButton :disabled="!authCode || $v.$anyError" @click="submit">
        {{ $t('continue') }}
      </UiButton>
    </div>
    <ui-alert v-model="visibleAlert">
      <h5 class="ui-alert__title">
        {{ $t('account-restored-successfully') }}
      </h5>
      <UiButton @click="nextStep">
        {{ $t('continue') }}
      </UiButton>
    </ui-alert>
  </div>
</template>


<script>
import {minLength, required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import UiAlert from "@/components/ui/UiAlert.vue";
export default {
  name: "RecoverAccountThirdStep",
  inject: ['form'],
  props: {
    password: {
      type: String,
      default: ''
    }
  },
  components: {
    UiAlert,
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
  },
  validations() {
    return {
      authCode: {
        required,
        minlength: minLength(5)
      },
    }
  },
  data() {
    return {
      authCode: '',
      catchError: false,
      buttonDisabled: false,
      visibleAlert: false
    }
  },
  computed: {
    getCodeErrorText() {
      if (!this.$v.authCode.minLength && !this.catchError) {
        return this.$t('code-must-be-5')
      }

      return this.$t('error-code')
    },
  },
  mounted() {
    document.addEventListener('keydown', this.enter)
  },

  methods: {
    ...mapActions(['restoreAccount']),
    sendCodeAgain() {
      this.buttonDisabled = true
      this.restoreAccount({
        email: this.form.email
      }).then(() => {
        this.buttonDisabled = false
      }).catch(() => {
        this.buttonDisabled = false
        this.openErrorModal = true
      })
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.restoreAccount({
          email: this.form.email,
          password: this.password,
          authCode: this.authCode
        }).then(() => {
          this.form.authCode = this.authCode
          this.visibleAlert = true;
        }).catch(() => {
          this.catchError = true
        })
      }
    },

    nextStep () {
      this.visibleAlert = false;
      setTimeout(() => {
        this.$emit('back')
      }, 300)
    },

    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.recover {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 116.7%;
    color: #343432;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }

  &__line {
    border-bottom: 1px solid #FFFFFF;
    margin: 20px 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
