<template>
  <div class="recover-account">
    <img src="@/assets/svg/logo/red.svg" alt="">
    <p class="recover-account__title" v-if="step >= 2">{{ $t('account-recovery') }}</p>
    <recover-account-first-step
        v-if="step === 1"
        @back="$emit('back')"
        @continue="step = 2"
    />
    <recover-account-second-step
        v-if="step === 2"
        @back="$emit('back')"
        @continue="step = 3"
    />
    <recover-account-third-step
        v-if="step === 3"
        @back="$emit('back')"
        :password="password"
    />
  </div>
</template>

<script>
import RecoverAccountSecondStep from "@/components/template/login/recover-account/RecoverAccountSecondStep.vue";
import RecoverAccountThirdStep from "@/components/template/login/recover-account/RecoverAccountThirdStep.vue";

export default {
  name: "RecoverAccount",
  props: {
    password: {
      type: String,
      default: ''
    }
  },
  provide: {
    form: {
      email: "",
      authCode: ""
    }
  },
  components: {
    RecoverAccountThirdStep,
    RecoverAccountSecondStep,
    RecoverAccountFirstStep: () => import("@/components/template/login/recover-account/RecoverAccountFirstStep.vue"),
  },
  data() {
    return {
      step: 1,
    }
  }
}
</script>

<style lang="scss" scoped>
.recover-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 44px;
    height: 50px;
    margin-bottom: 30px;
  }

  &__title {
    color: #000;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
::v-deep .login__form {
  padding: 30px 34px 35px 34px !important;
}
</style>
